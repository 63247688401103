import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Container, Grid } from '@material-ui/core';
import Page from 'src/components/Page';
import Header from './Header';
import Overview from './Overview';
import DashboardMap from './DashboardMap';
import PendingOrderAssign from './PendingOrderAssign';
import ActiveTechs from './ActiveTechs';
import LatestOrders from './LatestOrders';
import JobsCalendar from './JobsCalendar';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));

function DashboardAnalytics() {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Header />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Overview />
          </Grid>
          <Grid item lg={6} xs={12}>
            <PendingOrderAssign />
          </Grid>
          <Grid item lg={6} xs={12}>
            <DashboardMap />
          </Grid>
          <Grid item lg={12} xs={12}>
            <JobsCalendar />
          </Grid>
          <Grid item lg={8} xs={12}>
            <LatestOrders />
          </Grid>
          <Grid item lg={4} xs={12}>
            <ActiveTechs />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

export default DashboardAnalytics;
