import { useFirestoreConnect } from 'react-redux-firebase';

const RegionWrapper = ({ region, children }) => {
  console.log('subscribing', region);
  useFirestoreConnect([
    {
      collection: `/regions/${region}/techs`,
      storeAs: 'techs',
      orderBy: ['name', 'asc']
      // where: ['available', '!=', false]
    },
    {
      collection: `/regions/${region}/shops`,
      storeAs: 'shops',
      orderBy: ['name', 'asc']
    },
    { collection: `/regions/${region}/jobs`, storeAs: 'jobs' }
  ]);

  return children;
};

export default RegionWrapper;
