import React from 'react';
import { useFirestore } from 'react-redux-firebase';
import { Formik } from 'formik';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import RegionAddress from 'src/components/Region/RegionAddress';
import RegionDetails from 'src/components/Region/RegionDetails';
import { regionSchema } from 'src/utils/schemas';

const mbxGeocoding = require('@mapbox/mapbox-sdk/services/geocoding');

const useStyles = makeStyles(theme => ({
  actions: {
    marginTop: theme.spacing(3)
  }
}));

const initialValues = {
  name: '',
  address: {
    street: '',
    city: '',
    state: '',
    zip: ''
  },
  phone: ''
};

function AddRegionForm({ onSuccess, onError }) {
  const classes = useStyles();
  const firebase = useFirestore();

  const handleSubmit = (values, { setSubmitting }) => {
    (async () => {
      const {
        address: { city, state, street, zip }
      } = values;
      try {
        const geocoding = mbxGeocoding({
          accessToken:
            'pk.eyJ1Ijoia29wdGlvbmFsc29mdHdhcmUiLCJhIjoiY2treDQxdjlqMWdxaTMxbXlyMTc1MjF0aiJ9.nU7bEAZNngBALr03gZOqQg'
        });

        const res = await geocoding
          .forwardGeocode({
            query: `${street}, ${city}, ${state} ${zip}`,
            limit: 1
          })
          .send();
        const match = res.body;
        const firstResult = match?.features?.[0];
        const [lng, lat] = firstResult?.geometry?.coordinates ?? [null, null];
        if (!lat || !lng) {
          throw new Error(
            "We can't find this address unfortunately. Please try another one."
          );
        }

        firebase
          .add('regions', {
            ...values,
            center: new firebase.GeoPoint(lat, lng)
          })
          .then(createdRegion => {
            onSuccess &&
              typeof onSuccess === 'function' &&
              onSuccess(createdRegion);
          })
          .catch(err => {
            onError && typeof onError === 'function' && onError(err);
          })
          .finally(() => {
            setSubmitting(false);
          });
      } catch (err) {
        alert(err.message);
      }
      setSubmitting(false);
    })();
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={regionSchema}
    >
      {formik => (
        <form onSubmit={formik.handleSubmit}>
          <RegionDetails />
          <RegionAddress />
          <div className={classes.actions}>
            <Button
              color="primary"
              disabled={formik.isSubmitting}
              type="submit"
              variant="contained"
            >
              {formik.isSubmitting ? (
                <CircularProgress size={14} color="secondary" />
              ) : (
                'Add Region'
              )}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
}

AddRegionForm.propTypes = {
  region: PropTypes.object,
  onSuccess: PropTypes.func,
  onError: PropTypes.func
};

export default AddRegionForm;
