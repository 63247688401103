import * as yup from 'yup';
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const multiPhoneRegexp = /^((,( |\n)*)?((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?)+$/;
export const orderSchema = yup.object({
  contact: yup.string().required('required'),
  queue: yup.bool(),
  vehicle: yup.object({
    year: yup.number().required('required'),
    make: yup.string().required('required'),
    model: yup.string().required('required'),
    insurance: yup.string().required('required')
  }),
  date: yup.date().when('queue', {
    is: true,
    then: schema => schema,
    otherwise: schema => schema.required('required')
  }),
  shop: yup.string().required('required'),
  roNumber: yup.string().max(30),
  notes: yup.string(),
  customerName: yup.string().required('required')
});

export const shopSchema = yup.object({
  name: yup.string().required('required'),
  address: yup.object({
    street: yup.string().required('required'),
    city: yup.string().required('required'),
    state: yup.string().required('required'),
    zip: yup.string().required('required')
  }),
  phone: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .min(9, 'Phone number is not valid')
    .max(12, 'Phone number is not valid')
    .required('required'),
  notes: yup
    .string()
    .max(200, 'Shop notes must be less than 200 characters')
    .optional()
});

export const regionSchema = yup.object({
  address: yup.object({
    street: yup.string().required('required'),
    city: yup.string().required('required'),
    state: yup.string().required('required'),
    zip: yup.string().required('required')
  }),
  name: yup.string().required('required'),
  phone: yup
    .string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .min(9, 'Phone number is not valid')
    .max(12, 'Phone number is not valid'),
  notificationPhones: yup
    .string()
    .matches(
      multiPhoneRegexp,
      'Invalid format; please separate valid phones by commas'
    )
    .min(9, 'Phone number is not valid')
});

export const projectSchema = yup.object({
  address: yup.object({
    street: yup.string().required('required'),
    city: yup.string().required('required'),
    state: yup.string().required('required'),
    zip: yup.string().required('required')
  }),
  title: yup.string().required('required'),
  description: yup.string().required('required'),
  clientName: yup.string().required('required'),
  startDate: yup.date().required('required'),
  endDate: yup.date().required('required'),
  numberOfVehicles: yup.number().required('required')
});
