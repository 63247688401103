/* eslint-disable react/prop-types */
import React, {
  Suspense,
  useState,
  useEffect,
  useCallback,
  useContext
} from 'react';
import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { renderRoutes } from 'react-router-config';
import { Redirect, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  makeStyles,
  CardContent,
  Button,
  Typography,
  Card,
  DialogTitle,
  Dialog,
  LinearProgress,
  List,
  ListItemText,
  ListItem,
  ListItemSecondaryAction,
  IconButton
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import AddRegionForm from 'src/components/Region/AddRegionForm';
import RegionProvider, { RegionContext } from 'src/providers/RegionProvider';
import RegionWrapper from './RegionWrapper';
import TopBar from './TopBar';
import EditModal from 'src/views/RegionDetails/Summary/EditModal';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    '@media all and (-ms-high-contrast:none)': {
      height: 0 // IE11 fix
    }
  },
  content: {
    paddingTop: 64,
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden'
  },

  card: {
    width: 300,
    margin: 16,
    marginLeft: 'auto',
    marginRight: 'auto'
  },

  formControl: {
    minWidth: 120
  },

  newButtonWrapper: {
    marginTop: 64,
    textAlign: 'center'
  },

  dialogContent: {
    padding: theme.spacing(2),
    minWidth: 400,
    maxWidth: '100%'
  }
}));

// eslint-disable-next-line react/prop-types
function Dashboard({ route, location }) {
  const classes = useStyles();
  const { region, setRegion } = useContext(RegionContext);
  const [regionDropdownVal, setRegionDropdownVal] = useState('');
  const [regionRedirect, setRegionRedirect] = useState(null);
  const [goToRegionSelection] = useState(false);
  const [regionInitialized, setRegionInitialized] = useState(false);
  const [addingNewRegion, setAddingNewRegion] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(null);

  const history = useHistory();

  // GET USER TO FIGURE OUT REGION

  useFirestoreConnect([{ collection: 'regions' }]);

  useEffect(() => {
    setRegion(localStorage.getItem('REGION')); // this will be an ID or null
    setRegionInitialized(true);
    // need to also
    return () => {};
  }, [setRegion, setRegionInitialized]);
  const regions = useSelector(state => state.firestore.ordered.regions);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('regionId')) {
      const regionParam = queryParams.get('regionId');
      if ((regions ?? []).map(({ id }) => id).includes(regionParam)) {
        queryParams.delete('regionId');
        history.replace({
          search: queryParams.toString()
        });
        setRegionDropdownVal(regionParam);
      }
    }
  }, [history, regions, location.search]);

  // This is where we check to see if a region has been set.
  // if it is not and our current URL is not set regions then
  // we will redirect there accordingly
  useEffect(() => {
    // how to redirect
    if (
      regionInitialized &&
      !region &&
      !location.pathname.includes('/regions')
    ) {
      setRegionRedirect(location.pathname);
      const queryParams = new URLSearchParams(location.search);

      history.replace(
        `/regions${
          queryParams.has('regionId')
            ? `?regionId=${queryParams.get('regionId')}`
            : ''
        }`
      );
    }

    return () => {};
  }, [route, region, regionInitialized, location, history]);

  const configureRegion = useCallback(
    region => {
      setRegion(region);
      localStorage.setItem('REGION', region);
      history.replace(regionRedirect || '/techs');
    },
    [setRegion, history, regionRedirect]
  );

  useEffect(() => {
    if (regionDropdownVal) {
      configureRegion(regionDropdownVal);
    }
  }, [regionDropdownVal, configureRegion]);

  const handleAddRegionSuccess = () => {
    setAddingNewRegion(false);
  };

  const handleAddRegionError = err => {
    alert('There was an error adding that region: ' + err.message);
  };

  return (
    <>
      <TopBar
        resetRegion={() => {
          localStorage.removeItem('REGION');
          setRegion(null);
          setRegionDropdownVal('');
          history.replace('/regions');
        }}
        onOpenNavBarMobile={() => {}}
      />
      {goToRegionSelection && <Redirect to="/regions"></Redirect>}
      <div className={classes.container}>
        <div className={classes.content}>
          <Suspense fallback={<LinearProgress />}>
            {region ? (
              <RegionWrapper region={region}>
                {renderRoutes(route.routes)}
              </RegionWrapper>
            ) : (
              <div>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography>
                      Select which region you would like to control.
                    </Typography>
                    <List>
                      {(regions || []).map(region => (
                        <ListItem
                          key={region.id}
                          button
                          onClick={() => setRegionDropdownVal(region.id)}
                        >
                          <ListItemText
                            id={region.id}
                            primary={`${region.name}`}
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              color="secondary"
                              aria-label="Edit region"
                              size="small"
                              onClick={() => setSelectedRegion(region)}
                            >
                              <EditIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>

                    <div className={classes.newButtonWrapper}>
                      <Button
                        onClick={() => setAddingNewRegion(true)}
                        variant="contained"
                        color="primary"
                      >
                        Add a new region
                      </Button>
                    </div>
                  </CardContent>
                </Card>

                <EditModal
                  id={selectedRegion?.id}
                  onClose={() => setSelectedRegion(null)}
                  open={!!selectedRegion}
                  region={selectedRegion}
                />

                <Dialog
                  onClose={() => setAddingNewRegion(false)}
                  aria-labelledby="new-region-title"
                  open={addingNewRegion}
                  maxWidth="lg"
                >
                  <DialogTitle id="new-region-title">
                    Create a new region
                  </DialogTitle>

                  <AddRegionForm
                    onSuccess={handleAddRegionSuccess}
                    onError={handleAddRegionError}
                  />
                </Dialog>
              </div>
            )}
          </Suspense>
        </div>
      </div>
    </>
  );
}

function DashboardWithRegion(props) {
  return (
    <RegionProvider>
      <Dashboard {...props}></Dashboard>
    </RegionProvider>
  );
}

Dashboard.propTypes = {
  route: PropTypes.object
};

export default DashboardWithRegion;
