import { Button, colors, Tooltip } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BuildIcon from '@material-ui/icons/Build';
import { fromUnixTime } from 'date-fns';
import { differenceInDays } from 'date-fns/esm';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

function OrderTable({
  interactable = true,
  orders,
  title = 'Orders',
  filter,
  onFilterChange
}) {
  const shopMap = useSelector(state => state.firestore.data.shops);

  const formatted = orders.map(order => {
    const daysOld = differenceInDays(
      new Date(),
      fromUnixTime(order.createdAt / 1000)
    );
    return [
      order.type,
      order.queue
        ? `Queued for later: ${daysOld} day${daysOld === 1 ? '' : 's'} old`
        : order.date,
      order.shop,
      order.roNumber ?? '',
      order?.vehicle?.year ?? '',
      order?.vehicle?.make ?? '',
      order?.vehicle?.model ?? '',
      <Button
        key={order.id}
        to={`/orders/${order.id}`}
        component={RouterLink}
        color="primary"
        size="small"
        variant="outlined"
      >
        View
      </Button>,
      order.status
    ];
  });

  const isSm = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const columns = [
    {
      label: 'Type',
      options: {
        display: !isSm,
        customBodyRender: value => {
          return (
            <div style={{ display: 'flex' }}>
              <Tooltip title={value} placement="right">
                {value === 'Repair' ? (
                  <BuildIcon htmlColor={colors.grey[500]} />
                ) : (
                  <AssignmentIcon htmlColor={colors.grey[500]} />
                )}
              </Tooltip>
            </div>
          );
        }
      }
    },
    {
      label: 'Date',
      options: {
        filter: false,
        sortDirection: 'desc'
      }
    },
    {
      label: 'Shop',
      options: {
        display: !isSm,
        customBodyRender: value => {
          if (!shopMap[value]) {
          }
          return shopMap?.[value] ? shopMap[value].name : '';
        }
      }
    },
    {
      label: 'Repair Order #'
    },
    {
      label: 'Year'
    },
    {
      label: 'Make'
    },
    {
      label: 'Model'
    },
    {
      label: 'Actions',
      options: {
        filter: false
      }
    },
    {
      label: 'Status',
      options: {
        filterList: filter && filter !== 'all' ? [filter] : null,
        customBodyRender: value => {
          return <span></span>;
        },
        customHeadLabelRender: value => null
      }
    }
  ];

  return (
    <MUIDataTable
      title={title}
      options={{
        elevation: interactable ? 1 : 0,
        print: false,
        download: false,
        viewColumns: false,
        selectableRows: 'none',
        responsive: !isSm ? 'scrollFullHeight' : 'scrollMaxHeight',
        resizableColumns: false,
        pagination: interactable,
        filter: interactable,
        search: interactable,
        onFilterChange: onFilterChange
      }}
      data={formatted}
      columns={columns}
    />
  );
}

OrderTable.propTypes = {
  className: PropTypes.string,
  orders: PropTypes.array,
  interactable: PropTypes.bool,
  onFilterChange: PropTypes.func,
  title: PropTypes.string,
  filter: PropTypes.string
};

OrderTable.defaultProps = {
  orders: []
};

export default OrderTable;
