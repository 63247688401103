import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardHeader, CardContent, Divider } from '@material-ui/core';
import GenericMoreButton from 'src/components/GenericMoreButton';
import ReactMapGL, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import DotIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    padding: 0,
    height: 400
  },
  inner: {
    minWidth: 700
  },
  chart: {
    padding: theme.spacing(4, 2, 0, 2),
    height: 400
  }
}));

function DashboardMap({ className, ...rest }) {
  const classes = useStyles();

  const [viewport, setViewport] = useState({
    latitude: 39.7392,
    longitude: -104.9903,
    zoom: 6
  });

  const jobs = useSelector(state => state.firestore.ordered.jobs) || [];
  const shopMap = useSelector(state => state.firestore.data.shops) || {};
  const pendingJobs = jobs.filter(job => job.status === 'pending');
  console.log(shopMap, jobs);
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader action={<GenericMoreButton />} title="Map" />
      <Divider />
      <CardContent className={classes.content}>
        <ReactMapGL
          {...viewport}
          height="100%"
          width="100%"
          mapStyle="mapbox://styles/mapbox/streets-v11"
          onViewportChange={setViewport}
          mapboxApiAccessToken="pk.eyJ1IjoiY2hlc2xpcCIsImEiOiJjazd2M2xqdTQwb29iM2ttOHBpNTl4bG5uIn0.qx6dZoPglYYAhPRdkwo-qA"
        >
          {pendingJobs.map(job =>
            shopMap[job.shop]?.lat && shopMap[job.shop]?.lng ? (
              <Marker
                key={job.id}
                latitude={shopMap[job.shop].lat}
                longitude={shopMap[job.shop].lng}
                offsetLeft={-10.5}
                offsetTop={-12}
              >
                <DotIcon color="secondary" style={{ fontSize: 20 }} />
              </Marker>
            ) : null
          )}
        </ReactMapGL>
      </CardContent>
    </Card>
  );
}

DashboardMap.propTypes = {
  className: PropTypes.string
};

export default DashboardMap;
