import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
} from '@material-ui/core';

import EditRegionForm from 'src/components/Region/EditRegionForm';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
}));

function EditModal({ open, onClose, className, region, id, ...rest }) {
  const classes = useStyles();

  if (!open) {
    return null;
  }

  return (
    <Modal onClose={onClose} open={open}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <EditRegionForm id={id} region={region} onSuccess={onClose} onError={onClose} onCancel={onClose} />
      </Card>
    </Modal>
  );
}

EditModal.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  region: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired
};

EditModal.defaultProps = {
  open: false,
  onClose: () => {}
};

export default EditModal;
