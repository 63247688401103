import { Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import Calendar from 'src/components/Calendar';
import { parse } from 'date-fns';
import CalendarFilter from '../../components/CalendarFilter';
import { useFirestore } from 'react-redux-firebase';
import format from 'date-fns/format';
import { RegionContext } from 'src/providers/RegionProvider';

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(3)
  }
}));

const JobsCalendar = () => {
  const classes = useStyles();
  const firestore = useFirestore();

  const [filterDate, setFilterDate] = useState({});
  const [jobs, setJobs] = useState([]);
  const [startDateUpdated, setStartDateUpdated] = useState('');
  const [endDateUpdated, setEndDateUpdated] = useState('');

  const shopMap = useSelector(state => state.firestore.data.shops);
  const techs = useSelector(state => state.firestore.ordered.techs);
  const techMap = useSelector(state => state.firestore.data.techs);

  const { region } = useContext(RegionContext);
  

  useEffect(() => {
    if (!startDateUpdated && !endDateUpdated) {
      return;
    }
    if (!region) {
      return;
    }

    let snap = firestore.collection(`/regions/${region}/jobs`);

    if (startDateUpdated) {
      const start = format(new Date(startDateUpdated), 'yyyy-MM-dd HH:mm');
      snap = snap.where('date', '>=', start);
    }

    if (endDateUpdated) {
      const end = format(new Date(endDateUpdated), 'yyyy-MM-dd HH:mm');
      snap = snap.where('date', '<=', end);
    }

    if (filterDate.shop && filterDate.shop !== 'null') {
      snap = snap.where('shop', '==', filterDate.shop);
    }

    if (filterDate.tech && filterDate.tech !== 'null') {
      snap = snap.where('assignee', '==', filterDate.tech);
    }

    return snap.onSnapshot(snapshot => {
      const regionJobs = snapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
      }));
      setJobs(
        (regionJobs || []).filter(job => {
          return job.status !== 'complete'
        }).map(job => ({
          title: `${job.status} ${job.type ?? job.damage} at ${shopMap?.[
            job.shop
          ]?.name ?? 'Shop'} (${techMap?.[job.assignee]?.name ?? "Unassigned"})`,
          start: parse(job.date, 'yyyy-MM-dd HH:mm', new Date()),
          url: `/orders/${job.id}`
        }))
      );
    });
  }, [
    firestore,
    filterDate,
    techs,
    techMap,
    shopMap,
    startDateUpdated,
    endDateUpdated,
    region
  ]);

  const filterCalendar = values => {
    setFilterDate(values);
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <CalendarFilter
          filterCalendar={filterCalendar}
          techs={techs}
          shops={shopMap}
        />
        {jobs && (
          <Calendar
            events={jobs}
            onStartDateUpdated={setStartDateUpdated}
            onEndDateUpdated={setEndDateUpdated}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default JobsCalendar;
