/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import DashboardAnalyticsView from './views/Dashboard';
import { UserIsAuthenticated, UserIsNotAuthenticated } from './utils/auth';

export default [
  {
    path: '/auth',
    component: UserIsNotAuthenticated(AuthLayout),
    routes: [
      {
        path: '/auth/password-reset',
        exact: true,
        component: lazy(() => import('src/views/ResetPassword'))
      },
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('src/views/Login'))
      },
      {
        path: '/auth/register',
        exact: true,
        component: lazy(() => import('src/views/Register'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('src/views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('src/views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('src/views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: UserIsAuthenticated(withRouter(DashboardLayout)),
    routes: [
      {
        path: '/',
        exact: true,
        component: DashboardAnalyticsView
      },
      {
        path: '/regions',
        exact: true,
        render: () => null
      },
      {
        path: '/calendar',
        exact: true,
        component: lazy(() => import('src/views/Calendar'))
      },
      {
        path: '/techs',
        exact: true,
        component: lazy(() => import('src/views/TechsList'))
      },
      {
        path: '/techs/:id',
        exact: true,
        component: lazy(() => import('src/views/TechDetails'))
      },
      {
        path: '/techs/:id/:tab',
        exact: true,
        component: lazy(() => import('src/views/TechDetails'))
      },
      {
        path: '/regions/create',
        exact: true,
        component: lazy(() => import('src/views/RegionCreate'))
      },
      {
        path: '/orders',
        exact: true,
        component: lazy(() => import('src/views/OrdersList'))
      },
      {
        path: '/orders/create',
        exact: true,
        component: lazy(() => import('src/views/OrderCreate'))
      },
      {
        path: '/orders/:id',
        exact: true,
        component: lazy(() => import('src/views/OrderDetails'))
      },
      {
        path: '/shops',
        exact: true,
        component: lazy(() => import('src/views/ShopsList'))
      },
      {
        path: '/shops/create',
        exact: true,
        component: lazy(() => import('src/views/ShopCreate'))
      },
      {
        path: '/shops/:id',
        exact: true,
        component: lazy(() => import('src/views/ShopDetails'))
      },
      {
        path: '/shops/:id/:tab',
        exact: true,
        component: lazy(() => import('src/views/ShopDetails'))
      },
      {
        path: '/projects/:id',
        exact: true,
        component: lazy(() => import('src/views/ProjectDetails'))
      },
      {
        path: '/projects/:id/:tab',
        exact: true,
        component: lazy(() => import('src/views/ProjectDetails'))
      },
      {
        path: '/projects',
        exact: true,
        component: lazy(() => import('src/views/ProjectsList'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];
