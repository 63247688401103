import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import OrderTable from 'src/components/OrderTable';
import { useSelector } from 'react-redux';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  progressContainer: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  arrowForwardIcon: {
    marginLeft: theme.spacing(1)
  }
}));

function LatestOrders({ className, ...rest }) {
  const classes = useStyles();

  const sortByDate = (orderA, orderB) => {
    return moment(orderB.date).valueOf() - moment(orderA.date).valueOf();
  };

  const allOrders = useSelector(state => state.firestore.ordered.jobs) || [];
  const sorted = allOrders.slice().sort(sortByDate);
  const orders = sorted.length > 6 ? sorted.slice(0, 6) : sorted;

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Latest Orders" />
      <Divider />
      <CardContent className={classes.content}>
        <OrderTable orders={orders} interactable={false} />
      </CardContent>
      <CardActions className={classes.actions}>
        <Button
          color="primary"
          component={RouterLink}
          size="small"
          to="/orders"
          variant="text"
        >
          See all
          <ArrowForwardIcon className={classes.arrowForwardIcon} />
        </Button>
      </CardActions>
    </Card>
  );
}

LatestOrders.propTypes = {
  className: PropTypes.string
};

export default LatestOrders;
