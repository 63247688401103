import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const RegionContext = createContext({
  region: null,
  setRegion() {}
});

const RegionProvider = ({ children }) => {
  const [region, setRegion] = useState(null);

  return (
    <RegionContext.Provider
      value={{
        region,
        setRegion
      }}
    >
      {children}
    </RegionContext.Provider>
  );
};

RegionProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default RegionProvider;
