import React from 'react';
import { useFirestore } from 'react-redux-firebase';
import { Formik } from 'formik';
import {
  Button,
  CardHeader,
  CircularProgress,
  CardContent,
  CardActions,
  Divider
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import RegionAddress from 'src/components/Region/RegionAddress';
import RegionDetails from 'src/components/Region/RegionDetails';
import { regionSchema } from 'src/utils/schemas';

const useStyles = makeStyles(theme => ({
  actions: {
    justifyContent: 'flex-end'
  }
}));

const initialValues = {
  name: '',
  address: {
    street: '',
    city: '',
    state: '',
    zip: ''
  },
  phone: ''
};

function EditRegionForm({ id, region, onSuccess, onError, onCancel }) {
  const classes = useStyles();
  const firestore = useFirestore();

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    if (
      region.address.street !== values.address.street ||
      region.address.zip !== values.address.zip
    ) {
      values.lat = null;
      values.lng = null;
    }
    firestore
      .update(`regions/${id}`, values)
      .then(() => onSuccess && onSuccess())
      .catch(err => {
        console.log(err);
        onError && onError();
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Formik
      initialValues={region || initialValues}
      onSubmit={handleSubmit}
      validationSchema={regionSchema}
    >
      {formik => (
        <form onSubmit={formik.handleSubmit}>
          <CardHeader title="Edit Region" />

          <Divider />

          <CardContent>
            <RegionDetails />
            <RegionAddress />
          </CardContent>

          <Divider />

          <CardActions className={classes.actions}>
            <Button onClick={() => onCancel && onCancel()}>Cancel</Button>
            <Button
              color="primary"
              disabled={formik.isSubmitting}
              type="submit"
              variant="contained"
            >
              {formik.isSubmitting ? (
                <CircularProgress size={14} color="secondary" />
              ) : (
                'Save'
              )}
            </Button>
          </CardActions>
        </form>
      )}
    </Formik>
  );
}

EditRegionForm.propTypes = {
  id: PropTypes.string.isRequired,
  region: PropTypes.object,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onCancel: PropTypes.func
};

export default EditRegionForm;
